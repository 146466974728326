import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import AboutComponent from '../components/About';
import SecondHeader from '../components/SecondHeader';

const Imprint: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const [content, setContent] = useState<string>(`# Angaben gemäß § 5 TMG

**WhatsLegal GmbH**  
Widenmayerstrasse 46,  
80538 München  

**Handelsregister:** HRB293133  
**Registergericht:** München  

**Vertreten durch:**  
Stefan Weiss, Geschäftsführer  

**Telefon:** +491796716336  
**E-Mail:** stefan.weiss@whatslegal.ai  

## Umsatzsteuer-ID

Umsatzsteuer-Identifikationsnummer gemäß § 27 a Umsatzsteuergesetz:

## EU-Streitschlichtung

Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit:  
[https://ec.europa.eu/consumers/odr/](https://ec.europa.eu/consumers/odr/)

Unsere E-Mail-Adresse finden Sie oben im Impressum.

## Verbraucherstreitbeilegung/Universalschlichtungsstelle

Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.

**Quelle:**  
[https://www.e-recht24.de/impressum-generator.html](https://www.e-recht24.de/impressum-generator.html)
`);
  // useEffect(() => {
  //   // Fetch the markdown file
  //   fetch('/imprint.md') // Adjust the path if necessary
  //     .then(response => response.text())
  //     .then(data => setContent(data))
  //     .catch(error => console.error('Error fetching markdown:', error));
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle: React.CSSProperties = {
    flex: '1',
  };

  return (
    <div style={containerStyle}>
      <Header />
      <SecondHeader title="Impressum" />
      <div style={mainContentStyle} className="container">

        <AboutComponent paragraphs={[content]} />
      </div>
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </div>
  );
};

export default Imprint;
