import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import AboutComponent from '../components/About';
import SecondHeader from '../components/SecondHeader';

const CookiePolicy: React.FC = () => {
  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle: React.CSSProperties = {
    flex: '1',
  };

  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const [content, setContent] = useState<string>(`Cookies sind kleine Textbausteine, die von unseren Servern an Ihren Computer oder Ihr Gerät gesendet werden, wenn Sie auf unsere Dienste zugreifen. Sie werden in Ihrem Browser gespeichert und später an unsere Server zurückgeschickt, damit wir kontextbezogene Inhalte bereitstellen können. Ohne Cookies wäre die Nutzung des Internets eine viel frustrierendere Erfahrung.

Wir verwenden Cookies, um Ihre Aktivitäten auf unserer Website zu unterstützen. Zum Beispiel:

- **Ihre Sitzung**: Damit Sie sich nicht erneut anmelden müssen.
- **Ihren Einkaufswagen**: Um Ihre Auswahl zu speichern.

Cookies werden auch verwendet, um uns zu helfen, Ihre Präferenzen zu verstehen, basierend auf früheren oder aktuellen Aktivitäten auf unserer Website (die Seiten, die Sie besucht haben), Ihre Sprache und Ihr Land zu verstehen. Dies ermöglicht es uns, Ihnen einen besseren Service zu bieten. 

Zusätzlich verwenden wir Cookies, um aggregierte Daten über den Website-Verkehr und die Website-Interaktion zu sammeln. Diese Daten helfen uns, Ihnen in Zukunft bessere Website-Erfahrungen und -Tools anzubieten.`);
  // useEffect(() => {
  //   // Fetch the markdown file
  //   fetch('/cookie.md') // Adjust the path if necessary
  //     .then(response => response.text())
  //     .then(data => setContent(data))
  //     .catch(error => console.error('Error fetching markdown:', error));
  // }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div style={containerStyle}>
      <Header />
      <SecondHeader title="Cookie-Richtlinien" />
      <div style={mainContentStyle} className="container">
     
        <AboutComponent paragraphs={[content]} />
      </div>
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </div>
  );
};

export default CookiePolicy;
