// src/pages/Home.tsx
import React from 'react';
import Header from '../components/Header';
import ChatBot from '../components/ChatBot';
import Articles from '../components/Articles';
import Iframe from '../components/Iframe';
import WhyWhatsLegal from '../components/WhyWhatsLegal';
import Footer from '../components/Footer';
import SecondHeader from '../components/SecondHeader';
import Footer2 from '../components/Footer2';
import Footer2Mobile from '../components/Footer2_Mobile';
import useWindowSize from '../hooks/useWindowSize';

const Home: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  return (
    <> 
      <Header />
      <SecondHeader title={'freie KI für Gesetze'}/>
      {/* <Banner /> */}
      <ChatBot />
      <WhyWhatsLegal /> 
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </>
  );
};

export default Home;
