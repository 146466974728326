import React, { useEffect, useState } from 'react';
import Header from '../components/Header';
import Footer2Mobile from '../components/Footer2_Mobile';
import Footer2 from '../components/Footer2';
import useWindowSize from '../hooks/useWindowSize';
import Stats from '../components/Stats';
import Team from '../components/Team';
import AboutComponent from '../components/About';
import SecondHeader from '../components/SecondHeader';
import Iframe from '../components/Iframe';

const About: React.FC = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  const containerStyle: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  };

  const mainContentStyle: React.CSSProperties = {
    flex: '1',
  };

  const [content, setContent] = useState<string>(`## Purpose:
Die Idee zu WhatsLegal entstand aus dem Wunsch, einen Beitrag zur Stärkung der Demokratie und Rechtsstaatlichkeit in Deutschland zu leisten. Wir stellten uns die Frage: Wie können wir mithilfe von Technologie den sperrigen Zugang zum Recht vereinfachen und den Inhalt verständlicher machen, damit alle Bürger*innen unsere Gesetze verstehen? Egal ob 8 Jahre oder 80 Jahre.

## Zielgruppe:
Alle Bürger*innen.

## Features:
* Macht Bundes- und Landesgesetze für alle Bürger*innen leicht zugänglich und verständlich.
* Bietet eine vertrauensvolle, anonyme Informationsquelle zur Selbsteinschätzung der eigenen rechtlichen Lage (keine Cookies, kein Tracking, keine Anmeldung, keine persönlichen Daten).
* Unterstützt Laien (und Profis) beim Recherchieren in Gesetzen.
* Befähigt (Legal Enablement) Bürger*innen zum Konsens durch Konfliktvermeidung.**
* Trägt potenziell zur Entlastung der Zivilgerichte bei.**
* Ist kostenlos.

**[Abschlussbericht „Erforschung der Ursachen des Rückgangs der Eingangszahlen bei den Zivilgerichten”](https://www.bmj.de/SharedDocs/Downloads/DE/Fachinformationen/Abschlussbericht_Eingangszahlen_Zivilgerichte.html)**

## Finanzierung:
Durch Werbung und Whitelabel refinanzieren wir das kostenlose Angebot für die Zivilgesellschaft.
Melden Sie sich gerne als potenzieller Werbepartner, wenn unser Purpose sich mit Ihren Unternehmenswerten deckt.
Melden Sie sich gerne als Whitelabel-Partner, wenn Sie unsere Technologie auf Ihrer Webseite einsetzen möchten.
`);
  // useEffect(() => {
  //   // Fetch the markdown file
  //   fetch('/about.md') // Adjust the path if necessary
  //     .then(response => response.text())
  //     .then(data => setContent(data))
  //     .catch(error => console.error('Error fetching markdown:', error));
  // }, []);

  return (
    <div style={containerStyle}>
      <Header />
      <SecondHeader title="About" />
      <div style={mainContentStyle} className="container">
        <AboutComponent paragraphs={[content]} />

      </div>

      <Iframe />
      {/* <Team /> */}
      {isMobile ? <Footer2Mobile /> : <Footer2 />}
    </div>
  );
};

export default About;
