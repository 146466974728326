import React from 'react';
import './css/Iframe.css';

const Iframe: React.FC = () => {
  return (
    <div className="videoplayers">
      <h2>neueste Videos</h2>
      <div className="videoplayerlist">
        <div className="video">
          <iframe
            src="https://share.synthesia.io/embeds/videos/30eb92cf-8033-4b5e-99b7-0349b2c60eb1"
            frameBorder="0"
            allowFullScreen
            title="Video 1"
          ></iframe>
        </div>
        <div className="video">
          <iframe
            src="https://share.synthesia.io/embeds/videos/30eb92cf-8033-4b5e-99b7-0349b2c60eb1"
            frameBorder="0"
            allowFullScreen
            title="Video 2"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Iframe;
