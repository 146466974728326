import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import './css/WhyWhatsLegal.css'; 

const WhyWhatsLegal: React.FC = () => {
  return (
    <div className="why-whatslegal">
      <h1>Warum WhatsLegal?</h1>
      <div className="reasons-container">
        <div className="reason">
          <div className="text">
            <h2>
              anonym <span className="checkmark">
                <svg fill="#ff7f00" height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.104 479.104">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M471.121,113.606l-50.758-50.741c-10.642-10.644-27.881-10.644-38.522,0L180.146,264.574L97.303,181.73c-10.644-10.643-27.898-10.658-38.538-0.017L7.99,232.504C-2.653,243.146-2.668,260.4,7.974,271.043L148.14,411.226c17.287,17.284,45.31,17.333,62.659,0.11c64.093-63.647,208.114-207.158,260.291-259.145c5.129-5.111,8.014-12.043,8.014-19.275C479.12,125.668,476.235,118.736,471.121,113.606z"></path>
                  </g>
                </svg>
              </span>
            </h2>
            <p>Bei WhatsLegal fragen Sie anonym, kein login, keine e-mail</p>
          </div>
        </div>
        <div className="reason">
          <div className="text">
            <h2>kostenlos <span className="checkmark">
            <svg fill="#ff7f00" height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.104 479.104">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M471.121,113.606l-50.758-50.741c-10.642-10.644-27.881-10.644-38.522,0L180.146,264.574L97.303,181.73c-10.644-10.643-27.898-10.658-38.538-0.017L7.99,232.504C-2.653,243.146-2.668,260.4,7.974,271.043L148.14,411.226c17.287,17.284,45.31,17.333,62.659,0.11c64.093-63.647,208.114-207.158,260.291-259.145c5.129-5.111,8.014-12.043,8.014-19.275C479.12,125.668,476.235,118.736,471.121,113.606z"></path>
                  </g>
                </svg>
              </span></h2>
            <p>WhatsLegal ist kostenlos für 10 Fragen pro Tag (10 weitere Fragen 2€)</p>
          </div>
        </div>
        <div className="reason">
          <div className="text">
            <h2>vertrauensvoll <span className="checkmark">
            <svg fill="#ff7f00" height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.104 479.104">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M471.121,113.606l-50.758-50.741c-10.642-10.644-27.881-10.644-38.522,0L180.146,264.574L97.303,181.73c-10.644-10.643-27.898-10.658-38.538-0.017L7.99,232.504C-2.653,243.146-2.668,260.4,7.974,271.043L148.14,411.226c17.287,17.284,45.31,17.333,62.659,0.11c64.093-63.647,208.114-207.158,260.291-259.145c5.129-5.111,8.014-12.043,8.014-19.275C479.12,125.668,476.235,118.736,471.121,113.606z"></path>
                  </g>
                </svg>
              </span></h2>
            <p>WhatsLegal referenziert auf die deutschen Bundesgesetze</p>
          </div>
        </div>
        <div className="reason">
          <div className="text">
            <h2>einfach <span className="checkmark">
            <svg fill="#ff7f00" height="24px" width="24px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 479.104 479.104">
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                  <g id="SVGRepo_iconCarrier">
                    <path d="M471.121,113.606l-50.758-50.741c-10.642-10.644-27.881-10.644-38.522,0L180.146,264.574L97.303,181.73c-10.644-10.643-27.898-10.658-38.538-0.017L7.99,232.504C-2.653,243.146-2.668,260.4,7.974,271.043L148.14,411.226c17.287,17.284,45.31,17.333,62.659,0.11c64.093-63.647,208.114-207.158,260.291-259.145c5.129-5.111,8.014-12.043,8.014-19.275C479.12,125.668,476.235,118.736,471.121,113.606z"></path>
                  </g>
                </svg>
              </span></h2>
            <p>Mit WhatsLegal kann jede*r Gesetze verstehen Egal ob 8 oder 80 Jahre</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhyWhatsLegal;
